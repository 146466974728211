/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { colors } from '@mui/material';

// import pdf1 from 'assests/Pdf/Resources/NPSYPR_AnnualReport.pdf';

import Container from 'components/Container';


const mock = [
  {
    color: colors.lightBlue[500],
    title: 'GOONJ 2024-25',
    subtitle: '',
    href: '/goonj-2024',
  },

  {
    color: colors.lightBlue[500],
    title: 'INTERNATIONAL DAY OF YOGA 2024-25',
    subtitle: '',
    href: '/yoga-day-2024',
  },

];

const Celebrations2024 = () => {
  return (

    <Box >
      <Typography align='center' variant='h4' fontWeight={700}>
    Celebrations 2024-25
     </Typography>

      <Container>
        
        <Grid container spacing={8}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={8} md={4} key={i}>
              <Box
                
                bgcolor={'alternate.main'}>
                <Box component={Card} padding={4} width={1} height={1}  bgcolor={'alternate.main'}>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        href ={item.href}
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Learn More
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>

  );
};

export default Celebrations2024;
