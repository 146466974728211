import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Celebrations2023 from './Celebrations2023';

import { Features, Advantages,} from './components';
import { Link, Typography } from '@mui/material';
import Celebrations2024 from './Celebrations2024';

const Celebration = () => {
  const theme = useTheme();
  return (
    <Main>
      <Container  maxWidth={0.95} marginTop={0.6} paddingTop={'0 !important'}>
          <br/><br/>
          <Celebrations2024/>
        </Container>

<Container  maxWidth={0.95} marginTop={0.6} paddingTop={'0 !important'}>
          <br/><br/>
          <Celebrations2023 />
        </Container>

        {/* <Container>
         <Advantages />
        </Container> */}
        <Container  maxWidth={0.95} marginTop={0.6} paddingTop={'0 !important'}>
          <br/><br/>
          <Features />
        </Container>

    </Main>
  );
};

export default Celebration;
